@import "custom";

.irat-layout {
  min-height: calc(100vh + 1px);

  &__controls {
    @include media-breakpoint-up(md) {
      width: 280px;
    }
    width: 100%;
  }
}
