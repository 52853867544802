.irat-auth {
  --amplify-components-authenticator-state-inactive-background-color: hsl(
    0deg 0% 90%
  );

  [data-amplify-authenticator] {
    align-items: center;
    background-image: url("./background.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
  }
}
