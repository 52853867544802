@use "sass:color";
@import "custom";

.irat-summary-card {
  transition: background-color 0.25s, border-color 0.25s;
  border-width: 1px;
  border-style: solid;

  .irat-summary-card__status {
    transition: background-color 0.25s;
  }

  &--is-same,
  &--is-neutral {
    background-color: color.scale($irat-same-color, $alpha: -95%) !important;
    border-color: $irat-same-color !important;

    .irat-summary-card__status {
      background-color: $irat-same-color;
    }
  }

  &--is-better {
    background-color: color.scale($irat-better-color, $alpha: -95%) !important;
    border-color: $irat-better-color !important;

    .irat-summary-card__status {
      background-color: $irat-better-color;
    }
  }

  &--is-worse {
    background-color: color.scale($irat-worse-color, $alpha: -95%) !important;
    border-color: $irat-worse-color !important;

    .irat-summary-card__status {
      background-color: $irat-worse-color;
    }
  }

  &--is-loading {
    .irat-summary-card__status {
      background-color: $irat-same-color;
    }
  }

  &__value {
    font-size: 1.3em;
  }
}
