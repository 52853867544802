.irat-pdf {
  .irat-charts-container {
    display: block !important;

    &::after {
      clear: both;
      content: " ";
      display: block;
    }
  }
}
