@use "sass:color";

$primary: #0066cc;
$success: #009900;
$info: #5bc0de;
$danger: #ab2121;
$warning: #f0ad4e;
$font-family-sans-serif: Roboto, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "Roboto Condensed", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$enable-negative-margins: true;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

$irat-better-color: $success;
$irat-worse-color: $danger;
$irat-same-color: $gray-600;
$irat-title-color: $warning;
$irat-a-color: color.scale($orange-500, $saturation: -30%);
$irat-b-color: color.scale($purple-600, $saturation: -30%);
$irat-rain: $primary;
$irat-evap: $irat-worse-color;
$irat-tempmin: $irat-rain;
$irat-tempmax: $irat-worse-color;
$irat-radiation: #8b4513;
