.irat-pdf {
  .irat-chart-container {
    break-inside: avoid;
    height: 500px;
    float: left;

    &:nth-child(3n) {
      &::after {
        clear: both;
        content: " ";
        display: block;
        width: 100%;
      }
    }
  }
}
