@page {
  size: 1867px 2640px;
  margin: 30px 0;
}

@media print {
  html,
  body {
    width: 1867px;
    height: 2640px;
  }
}

.irat-pdf {
  zoom: 1.33333;

  &__section {
    break-after: avoid;
    break-before: auto;
    break-inside: avoid;
  }
}
