@import "custom";

.irat-paddocks-nav {
  overflow-x: auto;
  overflow-y: hidden;

  &__item {
    &--active-dark {
      border-bottom-color: $dark !important;
    }
  }
}
