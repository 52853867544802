@import "custom";

.irat-chart-box {
  .apexcharts-series path {
    fill-opacity: 0.75 !important;

    &:nth-child(1),
    &:nth-child(2) {
      fill: $irat-a-color !important;
    }
  }
}
