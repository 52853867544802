@import "custom";

.irat-header {
  background-image: url("/background.jpg");
  background-position: center center;
  background-size: cover;

  &__title {
    color: $irat-title-color;
  }

  &__version {
    font-size: 1rem;
    opacity: 0.75;
  }
}
