@use "sass:color";
@import "custom";

.irat-table-count {
  &__row {
    &--better {
      border-color: $irat-better-color !important;
      > * {
        background-color: color.scale(
          $irat-better-color,
          $alpha: -95%
        ) !important;
      }
    }

    &--worse {
      border-color: $irat-worse-color !important;
      > * {
        background-color: color.scale(
          $irat-worse-color,
          $alpha: -95%
        ) !important;
      }
    }

    &--same {
      border-color: $irat-same-color !important;
      > * {
        background-color: color.scale(
          $irat-same-color,
          $alpha: -95%
        ) !important;
      }
    }
  }
}
