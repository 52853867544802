@import "custom";

.irat-paddock-settings {
  &__slider {
    padding-top: 3px;
  }
}

.rc-slider-handle-dragging {
  background-color: tint-color($component-active-bg, 70%) !important;
  box-shadow: $form-range-thumb-focus-box-shadow !important;
}
